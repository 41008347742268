
















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ActionButtonWithTooltip extends Vue {
  @Prop({ required: true, type: String }) icon!: string;
  @Prop({ required: true, type: String }) tooltip!: string;
  @Prop({ default: false, type: Boolean }) disabled!: string;
  @Prop({ default: false, type: Boolean }) loading!: string;
  @Prop({ default: "default", type: String }) color!: string;
}
